import classes from "../Components/modal.module.scss";

const singleHouseContent = (
  <div className={classes.textContainer}>
    <p className={classes.textParagraph}>
      Dom 80 m2 z dwiema sypialniami budowany na zamówienie na działce klienta.
    </p>
    <p className={classes.textParagraph}>
      Wygodny, piękny, ciepły i przyjazny dla środowiska. Projekt inspirowany
      architekturą polski zachodniej. Wnętrza o wysokości ponad 3 m. Dom bardzo
      słoneczny , jasny i przemyślany funkcjonalnie. Zaprojektowany i wykonany w
      zgodzie ze środowiskiem. Technologia budowy tradycyjna murowana z
      wykorzystaniem najnowocześniejszych materiałów dostępnych w trzeciej
      dekadzie 21 wieku. Wytrzymały na 21 wieczne zmiany klimatu. Budynek
      odporny na wichury oraz brodzenie w wodzie. Może być zbudowany w dowolnej
      lokalizacji w Polsce.
    </p>
    <p className={classes.textParagraph}>
      Cena 398 000 + 8 % VAT w stanie deweloperskim. Czas budowy wraz ze
      wszystkimi instalacjami zwykle 3 miesiące. Projekt domu wliczony w cenę
      budowy. Przygotowanie dokumentacji w zależności od specyfiki działki oraz
      wymagań lokalnych wyceniane osobno.
    </p>
    <p className={classes.textParagraph}>
      Najwyższa jakość materiałów i wykonawstwa.
    </p>
    <p className={classes.textParagraph}>Numer projektu w kolekcji: ZP80</p>
    <button
      className={classes.learnMoreText}
      onClick={(event) => {
        event.stopPropagation();
        window.open("/Pdf/ProjectPdf.pdf");
      }}
    >
      Szczegóły funkcjonalne
    </button>
    <p className={classes.textParagraph}>
      Zapytaj o szczegóły:{" "}
      <a href="mailto:sprzedaz@21dom.pl">sprzedaz@21dom.pl</a> tel.660715587
    </p>
  </div>
);

const doubleStoryHouseContent = (
  <div className={classes.textContainer}>
    <p className={classes.textParagraph}>Dom piętrowy 72 m2 na małą działkę.</p>
    <p className={classes.textParagraph}>
      <span>Wkrótce dostępny.</span>
    </p>

    <p className={classes.textParagraph}>
      Zapytaj o szczegóły:{" "}
      <a href="mailto:sprzedaz@21dom.pl">sprzedaz@21dom.pl</a> tel.660715587
    </p>
  </div>
);

export const HOUSES = [
  {
    id: 1,
    image: "/Images/HouseSingleFloorFront.png",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 2,
    image: "/Images/HouseSingleFloorBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 3,
    image: "/Images/DoubleStoryFront.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 4,
    image: "/Images/DoubleStoryBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 5,
    image: "/Images/HouseSingleFloorFront.png",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 6,
    image: "/Images/HouseSingleFloorBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 7,
    image: "/Images/DoubleStoryFront.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 8,
    image: "/Images/DoubleStoryBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 9,
    image: "/Images/HouseSingleFloorFront.png",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 10,
    image: "/Images/HouseSingleFloorBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 11,
    image: "/Images/DoubleStoryFront.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 12,
    image: "/Images/DoubleStoryBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 13,
    image: "/Images/HouseSingleFloorFront.png",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 14,
    image: "/Images/HouseSingleFloorBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: singleHouseContent,
  },
  {
    id: 15,
    image: "/Images/DoubleStoryFront.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
  {
    id: 16,
    image: "/Images/DoubleStoryBack.jpg",
    pdf: "/Pdf/ProjectPdf.pdf",
    content: doubleStoryHouseContent,
  },
];
