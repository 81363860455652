import { useEffect } from "react";
import { useSwiper } from "swiper/react";

const SwiperManager = ({ children }) => {
  const swiper = useSwiper();

  console.log("helo");

  useEffect(() => {
    const interval = setInterval(() => {
      swiper.slideNext();
    }, 10000);
    return () => clearInterval(interval);
  }, [swiper]);

  return <div>{children}</div>;
};

export default SwiperManager;
