import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";

import RootLayout from "./pages/RootLayout";
import About from "./pages/About";
import PartnershipProjects from "./pages/PartnershipProjects";
import OurHouses from "./pages/OurHouses";
import Contact from "./pages/Contact";
import Shareholding from "./pages/Shareholding";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      children: [
        {
          path: "inwestycje-deweloperskie",
          element: <PartnershipProjects />,
        },
        { path: "o-nas", element: <About /> },
        { index: true, element: <OurHouses /> },
        { path: "kontakt", element: <Contact /> },
        { path: "akcjonariat", element: <Shareholding /> },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;
