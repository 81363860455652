import classes from "./MobileNavigation.module.scss";

import { Link, useLocation, useNavigate } from "react-router-dom";

const MobileNavigation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <nav
      className={`${classes.navigationContainer} ${
        props.isActive ? classes.navigationActive : undefined
      }`}
    >
      <ul className={classes.navigationList}>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/" ? "active" : ""
          }`}
        >
          <Link to={"./"}>Domy na zamówienie</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/inwestycje-deweloperskie" ? "active" : ""
          }`}
        >
          <Link to={"./inwestycje-deweloperskie"}>
            Inwestycje deweloperskie
          </Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/o-nas" ? "active" : ""
          }`}
        >
          <Link to={"./o-nas"}>O nas</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/akcjonariat" ? "active" : ""
          }`}
        >
          <Link to={"./akcjonariat"}>Akcjonariat</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/kontakt" ? "active" : ""
          }`}
        >
          <Link to={"./kontakt"}>Kontakt</Link>
        </li>
      </ul>
    </nav>
  );
};

export default MobileNavigation;
