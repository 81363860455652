import { useEffect } from "react";

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

import { EffectFade } from "swiper/modules";

import SwiperManager from "../Swiper/SwiperManager";

import classes from "./Background.module.scss";
import useIsHouseTab from "../Hooks/is-house-tab";
import { HOUSES } from "../utils/houses";

const BACKGROUND_IMAGES = [
  "/Images/BackgroundImageFront.jpg",
  "/Images/BackgroundImageBack.jpg",
];

const Background = () => {
  const isHouseTab = useIsHouseTab();

  const BackgroundImagesPool = isHouseTab
    ? HOUSES.map((item) => item.image)
    : BACKGROUND_IMAGES;

  return (
    <div className={classes.wrapper}>
      <Swiper
        loop={true}
        slidesPerView={1}
        modules={[EffectFade]}
        effect={"fade"}
        speed={3000}
      >
        <SwiperManager />
        {BackgroundImagesPool.map((item) => (
          <SwiperSlide>
            <div
              className={`${classes.background} `}
              style={{
                backgroundImage: `url(${item})`,
              }}
            />{" "}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Background;
