import { Outlet } from "react-router";

import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Background from "../Background/Background";

import classes from "./RootLayout.module.scss";
import Overlays from "../Components/Overlays";

function RootLayout() {
  return (
    <>
      <Overlays />
      <div className={classes.app}>
        <Header />
        <Outlet />
        <Footer />
      </div>
      <Background />
    </>
  );
}

export default RootLayout;
