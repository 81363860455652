import classes from "../Sass/Main.module.scss";

const Shareholding = () => {
    return (
        <main className={classes.main}>
            <h2>Akcjonariat</h2>
            <p className={classes.mainText}>21 DOM Prosta Spółka Akcyjna z siedziba w Warszawie </p>
            <p className={classes.mainText}>
                Aleja Jana Pawła II 61A/117 <br />01-031 Warszawa
            </p>
            <p className={classes.mainText}>
                Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego <br />
                Numer KRS 0001059567 <br />
                Wysokość Kapitału Akcyjnego 2000 PLN w całości wpłacony <br />
                Regon 526470780 <br />
                NIP 5273076660
            </p>
            <p className={classes.mainText}>Aktualny wykaz akcjonariuszy dostępny jest poprzez Elektroniczny Rejestr Akcjonariuszy <br />Prowadzony w Domu Maklerskim NAVIGATOR SA</p>
        </main>
    );
};

export default Shareholding;
