import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useIsHouseTab = () => {
  const [isHouseTab, setIsHouseTab] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsHouseTab(location.pathname === "/");
  }, [location]);

  return isHouseTab;
};

export default useIsHouseTab;
