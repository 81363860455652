import { useAtomValue, useSetAtom } from "jotai";
import { uiAtom } from "../utils/state";

import { IoCloseOutline } from "react-icons/io5";

import classes from "./modal.module.scss";

const Modal = () => {
  const ui = useAtomValue(uiAtom);
  const setUi = useSetAtom(uiAtom);

  const closeModal = (event) => {
    setUi((prev) => ({
      ...prev,
      data: {},
      isOpen: false,
    }));
  };

  const viewPdf = (event) => {
    event.stopPropagation();

    window.open(ui.data.pdf);
  };

  const stopProp = (event) => {
    event.stopPropagation();
  };

  return (
    <div className={classes.modal} onClick={closeModal}>
      <button className={classes.closeButton} onClick={closeModal}>
        <IoCloseOutline />
      </button>
      <div className={classes.container} onClick={stopProp}>
        <div className={classes.logoContainer}>
          <h1>21dom</h1>
        </div>
        <img src={ui.data.image} className={classes.houseImage} />
        {ui.data.content};
      </div>
    </div>
  );
};
export default Modal;
