import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "../Sass/swiperStyles.scss";

import classes from "./OurHouses.module.scss";
import { HOUSES } from "../utils/houses";
import HouseImage from "../House/HouseImage";

const OurHouses = () => {
  return (
    <main className={`${classes.main}`}>
      <Swiper
        className="houseSlider"
        modules={[Navigation]}
        spaceBetween={60}
        loop
        slidesPerView={"auto"}
        centeredSlides={true}
        navigation
      >
        {HOUSES.map((item) => (
          <SwiperSlide key={item.id}>
            <HouseImage data={item} />
          </SwiperSlide>
        ))}
      </Swiper>
    </main>
  );
};

export default OurHouses;
