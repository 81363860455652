import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  // faSquareFacebook,
  // faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <div className={classes.adress}>
        <h3>21dom Prosta Spółka Akcyjna</h3>
        <p>Aleja Jana Pawła II</p>
        <p>nr 61A, lok. 117</p>
        <p>01-031 Warszawa</p>
        <p>NIP: 5273076660</p>
      </div>
      <div className={classes.socialMedia}>
        <p>
          <a
            href="https://www.instagram.com/21dom.pl/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
