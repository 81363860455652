import classes from "./Logo.module.scss";
import { Link } from "react-router-dom";

const Logo = () => {
    return (
        <div className={classes.logoContainer}>
            <Link to={"./"}><h1>21dom</h1></Link>
        </div>
    );
};

export default Logo;
