import classes from "./HouseImage.module.scss";

import { useSetAtom } from "jotai";
import { uiAtom } from "../utils/state";

const HouseImage = ({ data }) => {
  const setUi = useSetAtom(uiAtom);

  const showHouseModal = () => {
    console.log("test");
    setUi((prev) => ({
      ...prev,
      data: data,
      isOpen: true,
    }));
  };

  return (
    <div className={classes.house} onClick={showHouseModal}>
      <img src={data.image} />
    </div>
  );
};

export default HouseImage;
