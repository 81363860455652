import { useState, useEffect } from "react";

import useIsMobile from "../Hooks/use-is-mobile";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Link, useLocation } from "react-router-dom";

import Logo from "../Logo/Logo";
import classes from "./Header.module.scss";
import MobileNavigation from "./MobileNavigation";

const Header = () => {
  const isMobile = useIsMobile();
  const location = useLocation();

  const [isBurgerActive, setIsBurgerActive] = useState(false);

  const onBurgerToggle = () => {
    setIsBurgerActive(!isBurgerActive);
  };

  useEffect(() => {
    setIsBurgerActive(false);
  }, [location]);

  const navigation = isMobile ? (
    <div className={classes.burger} onClick={onBurgerToggle}>
      <FontAwesomeIcon icon={faBars} />
    </div>
  ) : (
    <nav className={classes.navigationContainer}>
      <ul className={classes.navigationList}>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/" ? "active" : ""
          }`}
        >
          <Link to={"./"}>Domy na zamówienie</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/inwestycje-deweloperskie" ? "active" : ""
          }`}
        >
          <Link to={"./inwestycje-deweloperskie"}>
            Inwestycje deweloperskie
          </Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/o-nas" ? "active" : ""
          }`}
        >
          <Link to={"./o-nas"}>O nas</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/akcjonariat" ? "active" : ""
          }`}
        >
          <Link to={"./akcjonariat"}>Akcjonariat</Link>
        </li>
        <li
          className={`${classes.navigationItem} ${
            location.pathname === "/kontakt" ? "active" : ""
          }`}
        >
          <Link to={"./kontakt"}>Kontakt</Link>
        </li>
      </ul>
    </nav>
  );

  return (
    <>
      <header>
        <Logo />
        {navigation}
      </header>
      {isMobile && <MobileNavigation isActive={isBurgerActive} />}
    </>
  );
};

export default Header;
