import classes from "../Sass/Main.module.scss";

const Contact = () => {
    return (
        <main className={classes.main}>
            <h2>Kontakt</h2>
            <p className={classes.mainText}>
                <a href="mailto:kontakt@21dom.pl">kontakt@21dom.pl</a><br /><br />

                21dom PSA<br />
                ul. Aleja Jana Pawła II,<br />
                nr 61A,  lok. 117<br />
                01-031 Warszawa<br />
                NIP: 5273076660<br />
            </p>
        </main>
    );
};

export default Contact;
