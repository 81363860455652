import classes from "../Sass/Main.module.scss";

const PartnershipProjects = () => {
  return (
    <main className={classes.main}>
      <h2>21 dom</h2>
      <br />
      <h2>Nowe inwestycje wkrótce</h2>
      {/* <p className={classes.smallerMainText}>
        Zamieszkaj wygodnie we własnym domu. W kameralnym, pięknie położonym
        osiedlu składającym się maksymalnie z 5 domów.
      </p>
      <p className={classes.smallerMainText}>
        Ciesz się bliskością natury, rzeczki Rządza, lasu a także wygodnym
        dojazdem do pobliskich terenów rekreacyjnych nad Jeziorem Zegrzyńskim. W
        Radzyminie <span>5 km</span> od domu, znajdziesz nowoczesne i wygodne
        sklepy, szkołę i stację SKM z której dojedziesz do Warszawy. Dojazd do
        Wisłostrady w Warszawie trasą ekspresową S8 zajmie Ci tylko{" "}
        <span>25 minut</span>.
      </p>
      <p className={classes.smallerMainText}>
        Własność działek o powierzchni od <span>350 m2</span> do{" "}
        <span>435 m2</span> zapewni Tobie komfort i intymność.
      </p>
      <p className={classes.smallerMainText}>
        Twój dom będzie wybudowany w 21 - wiecznej technologii. Będzie ciepły,
        wygodny i oszczędny w eksploatacji. Przed swoim domem wygodnie
        zaparkujesz minimum <span>2 auta</span>.
      </p>
      <p className={classes.smallerMainText}>
        Dom w cenie kawalerki w Warszawie? Z nami to możliwe.
      </p>
      <p className={classes.smallerMainText}>
        <a href="mailto:sprzedaz@21dom.pl">sprzedaz@21dom.pl</a>
        <br />
        <a href="tel:+48660715587">+ 48 660715587</a>
      </p> */}
    </main>
  );
};

export default PartnershipProjects;
