import classes from "../Sass/Main.module.scss";

const About = () => {
  return (
    <main className={classes.main}>
      <h2>O nas</h2>
      <p className={classes.mainText}>
        Projektujemy i budujemy piękne, proste domy inspirowane tradycyjną
        polską architekturą. Nasze domy są energooszczędne, wygodne i wykonane w
        21 wiecznej technologii z poszanowaniem polskiej tradycji. Korzystamy z
        najlepszych dostępnych materiałów i rozwiązań.
      </p>
      <p className={classes.mainText}>
        Nasze domy są zbudowane w sposób tradycyjny, są trwałe i ponadczasowe.
        Używamy wysokospecjalizowanych narzędzi oraz wyjątkowo sprawnej
        logistyki dlatego proces budowlany jest skrócony do absolutnego minimum.
      </p>
      <p className={classes.mainText}>
        Dom jednorodzinny w każdym zakątku Polski nigdy nie był tak dostępny.
      </p>
      <p className={classes.mainText}>Zapraszamy do kontaktu</p>
      <p className={classes.mainText}>
        <a href="mailto:kontakt@21dom.pl">kontakt@21dom.pl</a>
      </p>
    </main>
  );
};

export default About;
